//------------------------------------------------------------------------------
//-- IMPORTS
import React, { useState, useEffect } from 'react';


//------------------------------------------------------------------------------
//-- NetworkLookup Component
export default function NetworkLookup() {

  //----------------------------------------------------------------------------
  //-- States

  //-- OBJ to hold info
    const [networkLookupElements] = useState({
    ip        :   "network-ip",
    hostname  :   "network-hostname",
    city      :   "network-city",
    region    :   "network-region",
    country   :   "network-country",
    loc       :   "network-loc",
    org       :   "network-org",
    postal    :   "network-postal",
    timezone  :   "network-timezone"
  });

  //----------------------------------------------------------------------------
  //-- Functions

  //-- Update HTML Elements
  const set_PublicIp = async function (PublicIpResults) {
    Object.keys(networkLookupElements).forEach( element  => {
      document.getElementById(networkLookupElements[element]).innerText = PublicIpResults[element];
    })
  }

  //-- Function to make Fetch Request for Public IP from ipinfo.com
  const get_PublicIP = async function () {
    //-- Container to hold results
    let PublicIP = {}
    try{
      //TODO:: 04/29/22 #EP || Get new token and move to .env
      const response = await fetch("https://ipinfo.io/json?token=5ab2f0f1e50427");
      //-- if success to getting this info        
        
      if (response.ok) {
        await response.json()
          .then(data => { PublicIP = data })
          .catch(err => { console.log(err) });
        
        return response, PublicIP;
      } 
      else {
          return response;
      }
    }
    catch(err){
      console.log(err)
      return false;
    }
  };


  //----------------------------------------------------------------------------
  //-- Run Once Event

  useEffect(() => {
    // document.title = `posnation.support - NetworkLookup`;
    const publicIP = get_PublicIP()
      .then( PublicIpResults => set_PublicIp(PublicIpResults) )
      .catch()
  },[]);

  //----------------------------------------------------------------------------
  //-- RETURN FUNCTION
  return (    
    <div className='content'>
      <section className='container resources' id='network-section'>
        {/* <!-- PAX Submission form results --> */}
        {/* <div className='result-parent'> */}
          <h2>Public Network</h2>
          <article className='' id="network-request-results">  
            {/* the button form */}
            <label><h4>ISP Public Network Details</h4></label>
            
              
            
            {/* the results container */}
            <div id="network-lookup-results">
              {/* <!-- TODO:: 03/22/2022 #EP || Add Copy Button --> */}
              {/* <span>
                <h4 id="pax-h4">PAX Lookup Results</h4>
              </span> */}
              <span className='network-lookup-result'>
                <code className='label'>ip</code>
                <code className='results' id="network-ip">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>hostname</code>
                <code className='results' id="network-hostname">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>city</code>
                <code className='results' id="network-city">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>region</code>
                <code className='results' id="network-region">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>country</code>
                <code className='results' id="network-country">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>loc</code>
                <code className='results' id="network-loc">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>org</code>
                <code className='results' id="network-org">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>postal</code>
                <code className='results' id="network-postal">-</code>
              </span>
              <span className='network-lookup-result'>
                <code className='label'>timezone</code>
                <code className='results' id="network-timezone">-</code>
              </span>
            </div>
          
          </article>            
        {/* </div> */}
      </section>
    </div>

  
  )
}
