import React from "react";
import PaxLookup from "../../pages/PaxLookup";
import NetworkLookup from "../../pages/NetworkLookup";

export default function Home() {
  return (
    <>
      <section className="container banner" id="banner_Index">
        <h1>Home</h1>
      </section>

      {/* <!-- Links to resources --> */}
      {/* <section className="container resources">
        <h2>Downloads</h2>
        <article>
          <form method="get" action="./cap_current.html">
            <h4>CAP Current Patch Bucket</h4>
            <p className="note">
              A link to the current Module Patches for the CAP full installer
              version 09.5.015.
            </p>
            <button className="folder_BTN" style="margin:5px;" type="submit">
              CAP 09.5.015
            </button>
          </form>
        </article>

        <article>
          <h4>
            All resources on this page are designed and meant to be used by or
            at the direct advisement of POS Nation employees.
          </h4>
          <p className="note">
            A link to ALL cap module downloads and related resources.
          </p>
          <button className="folder_BTN" type="submit">
            All CAP Versions
          </button>
        </article>
      </section> */}

      {/* <section> */}
      <NetworkLookup></NetworkLookup>
      <PaxLookup></PaxLookup>
      {/* </section> */}
    </>
  );
}
