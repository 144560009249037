// import {set_Database, get_Database, run_Database } from './database.js';
// import { get_AWS_Resources } from '../js/aws.js';
import {get_PublicIP } from './id-ip.js';
import { set_StatusBar } from './status-logic.js';


/*----------------------------------------------------------------------------*/
//-- MODAL


async function _set_Modals(){
  //-- Set Modal event listeners and beahviors
  
  //-- Adding event listeners for settings modal
  const _set_Resources = async () => {
    // Get the modal
    var modal_Resources = document.getElementById("modal-Resources");

    // Get the button that opens the modal
    var btn = document.getElementById("nav-resources-a");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close-resources")[0];

    // When the user clicks the button, open the modal 
    btn.onclick = function() {
      modal_Resources.style.display = "block";
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal_Resources.style.display = "none";
    };
  };

  //-- Adding event listeners for settings modal
  const _set_Settings_Modal = async () => {
    // Get the modal
    try{
      var modal_Settings = document.getElementById("modal-Settings");

      // Get the button that opens the modal
      var btn = document.getElementById("nav-settings-a");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close-settings")[0];

      // When the user clicks the button, open the modal 
      btn.onclick = function() {
        modal_Settings.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      span.onclick = function() {
        modal_Settings.style.display = "none";
      };
    }
    catch(err){
      // console.log(`Settings Disabled: ${err}`)
    }
  };

  // const _set_Modal_ClickOutside = async () => {
  window.onclick = function(event) {
    // When the user clicks anywhere outside of the modal, close it  

    if (event.path[0].id === 'modal-Settings') {
      document.getElementById('modal-Settings').style.display = "none";
    }
    else if (event.path[0].id === 'modal-Resources') {
      document.getElementById('modal-Resources').style.display = "none";
    }; 
  };

  const _set_Modal_ClickOutside = async () => {
    window.onclick = function(event) {
      // When the user clicks anywhere outside of the modal, close it  

      if (event.path[0].id === 'modal-Settings') {
        document.getElementById('modal-Settings').style.display = "none";
      }
      else if (event.path[0].id === 'modal-Resources') {
        document.getElementById('modal-Resources').style.display = "none";
      }; 
    };
  };  
  // _set_Resources()
  // .then(_set_Settings())
  // .then(_set_Modal_ClickOutside());
};


//-- Update if elements exist
const _set_Settings = async function(){
    const PublicIP = await get_PublicIP()
    const statusBar =await set_StatusBar(PublicIP)
    return statusBar;
}

/*----------------------------------------------------------------------------*/
//-- RUNNING

const run = async function() {

  console.warn("TODO: Enable this scripts/actually o nboard them. ATM they're not ");
  console.log('_set_Modals: ', await _set_Modals());
  console.log('_set_Settings: ', await _set_Settings());


  //-- Updates AWS Resources in Local Storage
  // console.log("//--script.js --> runDatabase()...");
  
  // TODO:: 03/25/2022 #EP || Add this back once fix moment.js importing
  // run_Database()
  // .then(() => {
  //   // console.log("//--script.js --> then...");
  //   //  get_AWS_Resources();
  // })
  // .then( () =>{
  //    _set_Modals();
  // })
  
  // _set_Modals()
  // .then(_set_Settings())
  
  //TODO:: 05/05/22 #EP || Remove this 100% and onboard to React
  // const settings = await _set_Settings()
  //   .then(results  => {
  //     // _set_Settings(results)
  //     console.log("Results...", results)
  //   })
  //   .catch(err => console.table(err));
  //   // .then(() => _get_Files_Details()
};

// console.log("script.js")
run();