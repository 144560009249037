//-- Take XML convert to Text and conver to JSON
const formatXMLtoJSON = async xmlRaw =>  {
    //-- json OBJ to return reuslts
    var xmlAsJSON = {};

    //-- Iterate through each line in response and return results
    xmlRaw.split(/>\s*</).forEach(function(node) {
        
        //-- strip out unused junk
        node = node.replace("/>,<,>","");
     
        var formatted = node.replace(">",":");
        formatted = formatted.split(":");
        
        if(formatted[1]){ 
            formatted[1] = (formatted[1]).split("</")
            // console.log(formatted[1])    
            xmlAsJSON[formatted[1][1]] = formatted[1][0];
            // if(formatted[1][0]){
            // }
        }
    });
    return xmlAsJSON; //formatted.substring(1, formatted.length-3);
}

const updatePaxResults = async results => {
    const paxLookupResultElements ={
        IPaddress   : "pax-IPaddress",
        MacAddress  : "pax-MacAddress",
        Port        : "pax-Port",
        ResultCode  : "pax-ResultCode",
        ResultMsg   : "pax-ResultMsg",
        SerialNo    : "pax-SerialNo"
    }
    
    // console.log(results)

    Object.keys(paxLookupResultElements).forEach( element  => {
        // console.log(`element: ${element}`)
        // console.log(`Results: ${paxLookupResultElements[element]}`)
        
        document.getElementById(paxLookupResultElements[element]).innerText = results[element];
    })

}

const getPaxNetworkInfo = async serialNumber => {
    /* 
        Make a Fetch Request with a Serial Number to get details from PAX, and 
        reutrns results as a Tech Request

            Example full URL Built
                - https://poslink.com/poslink/ws/process2.asmx/GetDeviceLocalIP?TerminalId=8&Token=&SerialNo=53863130
    */
   
    //-- to hold what is returned
    let response;
   
    //-- base URL to add on
    const paxNetwork = "https://poslink.com/poslink/ws/process2.asmx/GetDeviceLocalIP?TerminalId=8&Token=&SerialNo="
    
    //-- Making a request based on the serial-number
    const url = (paxNetwork + serialNumber);
    try {
        //-- make a tech request for SN
        response = await fetch( url,{})
            //-- convert results to text
            .then( results => {return results.text()})
            //-- convert to JSON
            .then(xmlRaw => {
                //-- If was wantin got play iwth XML, I'd use this -> // .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                return formatXMLtoJSON(xmlRaw);
            })
            //-- return results
            .then( results => {
                if (results) {
                    // return(console.table(results),{
                        return({
                        "status": 200,
                        "results": results,
                        "response":response
                    });
                } 
                else {
                    // console.log("no results")
                    return( {
                        "results": String(results),
                        "status": "Error",
                        "NOTE": 'ERROR: SSL Cert failure',
                        "response": response
                        }
                    );
                }
            })
            .then(results=>{
                updatePaxResults(results.results);
            })
    }
    catch (err) {
        // console.log("catch error",err)
        return {
            "status" : "Error",
            "message" : "catch failure",
            "response" : String(response),
            "error": String(err),
        }
    }
}


window.onload = () => {
    if(document.getElementById("pax-section")){
        document.getElementById("pax-lookup-submit").addEventListener('click', e => {
            e.preventDefault();
            const serialNumber = document.getElementById("pax-sn").value; //-- extract input value
            if(serialNumber.length >= 9)
            { //-- if a valid SN, make an API call
                const cleanSerialNumber = serialNumber.replaceAll("-",""); //-- remove all hyphens
                getPaxNetworkInfo(cleanSerialNumber); //-- make request
            }
        })
    }
};
