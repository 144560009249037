//------------------------------------------------------------------------------
//-- IMPORTS

//-- React Specific
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { v4 as uuidv4 } from 'uuid'; //-- Being used to generate unique keys for react
// import reportWebVitals from './utils/reportWebVitals'; //-- For dev awareness

//------------------------------------------------------------------------------
//-- RESOURCES
import './assets/css/styles.css';
import './assets/js/script';
import './assets/js/pax';
// import './assets/js/aws.js'
// import get_Folders from './assets/js/aws.js';

//-- jquery ( https://medium.com/how-to-react/how-to-use-jquery-in-your-react-app-b425727a32fd )
// import $ from 'jquery';

//-- Prepare for starting App
const container = document.getElementById('root');
const root = createRoot(container); 

//-- start App
root.render(
  <React.StrictMode key={uuidv4()}>
    {/* <App key={uuidv4()} uuidv4={uuidv4} AWS={AWS} get_Folders={get_Folders} $={$} /> */}
    {/* <App key={uuidv4()} uuidv4={uuidv4} $={$} /> */}
    <App key={uuidv4()} uuidv4={uuidv4} />
  </React.StrictMode>
);

// if in Development
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  console.log('dev')
  // reportWebVitals(); // Having web-vitals print for awareness.  ( SRC -> https://bit.ly/CRA-vitals )
} 
// else { console.log('prod')}// production 
