//------------------------------------------------------------------------------
//-- IMPORTS

import React from 'react';
import {  BrowserRouter, Route, Routes  } from 'react-router-dom';

//------------------------------------------------------------------------------
//-- UNIVERSAL PAGE ELEMENTS

import Navigation from './components/Navigation'; //-- Header and Nav
// import Statusbar from './components/Statusbar';   //-- POS Nation centric stats
import Modals from './components/Modals';         //-- Popups
import Footer from './components/Footer';         //-- Copyright

//------------------------------------------------------------------------------
//-- PAGES / ROUTES
import Sandbox from './pages/Sandbox';
import Home from './pages/Home';                     //-- Default landing page
import PageNotFound from './pages/PageNotFound';     //-- Default landing page
import NetworkLookup from './pages/NetworkLookup';   //-- ipinfo.io ip grab
// import AWS from './pages/AWS'; //TODO: re-add once on-boarded again.
import PaxLookup from './pages/PaxLookup';
import Resources from './pages/Resources';
import SalesforceChat from './pages/SalesforceChat';


export default function App() {
  
  return(
      <BrowserRouter basename="/">
        
          <Navigation/>
          
          {/* ROUTES */}
          <section className="body-container">
            <Routes>
              <Route exact path="/" element={< Home />} />
              <Route exact path="/home" element={< Home />} />

              <Route exact path="/d" element={< Resources />} />
              <Route exact path="/download" element={< Resources />} />
              <Route exact path="/downloads" element={< Resources />} />
              <Route exact path="/res" element={< Resources />} />
              <Route exact path="/resources" element={< Resources />} />
              {/* <Route exact path="/aws" element={< AWS />} /> */}

              <Route exact path="/ip" element={< NetworkLookup />} />
              <Route exact path="/isp" element={< NetworkLookup />} />
              <Route exact path="/network" element={< NetworkLookup />} />
              <Route exact path="/NetworkLookup" element={< NetworkLookup />} />

              <Route exact path="/pax" element={<PaxLookup /> } />
              <Route exact path="/paxlookup" element={<PaxLookup /> } />

              <Route exact path="/chat" element={<SalesforceChat /> } />
              <Route exact path="/sandbox" element={<Sandbox /> } />
              
              {/* Anything else, 404 page */}
              <Route exact path="/*" element={< PageNotFound />} />
            </Routes>
          </section>

          
          <Modals></Modals>
          <Footer></Footer> 
        {/* </div> */}
      </BrowserRouter>
  );
};