//TODO:: 05/03/22 #EP || Review and make a plan for this to move into component
//-- All posnation.support Statusbar logic


const PATH = window.location.pathname;

const URLS = {
    // "status-help.posnation" : "https://help.posnation.com/",
    // "status-posnation" : "https://www.posnation.com/",
    "wpos.appcache" : "https://demo.bottlepos.com/wpos.appcache.php"

}

const STATUS_OPTIONS = {
    "Error: SSL peer certificate or SSH remote key was not OK" : "SSL Cert failure"
}


//-- RUNNING THROUGH URL GET STATUS.
const get_Status = async ( url ) => {
    
    console.log("status-logic.js > get_Status()...")
    
    try {
        const response = await fetch( `${url}`,
        {
            method: 'GET',
            mode: 'no-cors'
         })
         .then(reply => {return reply})

        //  console.log(response);
        if(response.ok) {
            const results = await response.json()
                .then(data => { return data })
                .catch(err => { console.log(err) });
            return results;
        }

        if(!(response.ok)) {
            // const results = await response.json();
            return response;
        }

        else {
            return "else";
        }
    }
    catch(err) {
        return err;
    }
};

// if (navigator.onLine) {
//     //TODO:: 05/03/22 #EP || Update page if offline vs online
//     console.log('online');
//   } else {
//     console.log('offline');
// }


const _print_StatusBar = ( results ) => {
    // console.log(`print_StatusBar URL: ${results.url}`);
    // console.log(`print_StatusBar STATUS: ${results.status}`);

}

//-- Running Program
const set_StatusBar = async (PublicIP) => {
    
    // console.table(PublicIP)
    const netowrkLookupResultElements = {
        ip: "network-ip",
        hostname:   "network-hostname",
        city:   "network-city",
        region: "network-region",
        country:    "network-country",
        loc:    "network-loc",
        org:    "network-org",
        postal: "network-postal",
        timezone:   "network-timezone"
    }

    Object.keys(netowrkLookupResultElements).forEach( element  => {
        // console.log(`element: ${element}`)
        // console.log(`Results: ${paxLookupResultElements[element]}`)
        
        document.getElementById(netowrkLookupResultElements[element]).innerText = PublicIP[element];
    })
    
    
    // let results = {};
    for(let url in URLS){
        console.log(`//-- Checking satus of ${URLS[url]}...`)
        // console.log(URLS[url])
        get_Status(URLS[url])
            .then(status => {
                console.log(`Request Completed. RESULTS:`,status)
                
                console.log("processing request...")
                
                if( status['status'] === "Error" ){
                    console.log("status is error")
                    return {
                        url: URLS[url],
                        status: 0
                    };
                }
                else {
                    console.log("else...",status['status'])
                    return { 
                        url: URLS[url],
                        status: 1 
                    };
                }
            })
            .then( results => _print_StatusBar(results) )
            .catch(err => {
                console.log(`Request Failed. ERROR: ${String(err)}`)
            });
    }
};

// get_Status()

//-- Exporting to share with script.js
export { set_StatusBar };
