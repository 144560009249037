
//-- used to get time passed on things
var moment = require('moment'); //-- Using to add more dynamic date-time in view


//------------------------------------------------------------------------------
//-- Data Validation Helpers

//-- verify if an email, return all lowercase.
export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

//------------------------------------------------------------------------------
//-- Data Manipulation

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getBytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};
  
export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

// A utility function to create HTML.
export function getHtml(template) {
  return template.join('');
};

export function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

//-- take a string payload, shrink it to a specific length. If length not defined, default is 100.
export function stringShrink(content, length) {
    
  //-- if undefined, default is 100 characters
  if(!length){ length = 100; }

  //-- if length is greater than 100 shrink it and then return that sub-string
  if (content.length > 100) return content.substring(0,100) + '...';
  
  //-- Otherwise return what was sent in no modification needed.
  return content;
}


//------------------------------------------------------------------------------
//-- Data Time Specific Helpers

//-- list the 12 months
export function dateGetMonths(){
  return moment.months();
}

export function dateTimeFullLocal(date){
  return moment(date).local().format("dddd, MMMM Do YYYY, h:mm:ss a");
}

export function dateTimeFull(date){
  // moment(date)
  return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
}

export function dateDayOfWeek(date){
  return moment(date).day();
}

export function dateHourOfDay(date){
  return moment(date).format("ddd, hA"); 
}


//-- Send in raw JavaScript Date/Time Value from date.Now() and returns MM/DD/YYYY format
export function dateFormat(date) {
  return moment(date).format('MM-DD-YYYY');

  // return `${
  //   new Date(date).getMonth() + 1}
  //   /${new Date(date).getDate()}
  //   /${new Date(date ).getFullYear()
  // }`;
};

//-- Send in raw JavaScript Date/Time Value from date.Now(), get time passed in seconds, minutes, hours or days.
export function dateGetTimePassed(date){
  //-- Get current time
  var now = moment(new Date()); 
  //-- Get the diff between now and created date
  var duration = moment.duration(now.diff(date));
  //-- Return value in hours
  var results = duration.asHours();

  if(results < 0.01){ let seconds = ((duration._data.seconds)) + " s"; return seconds; }
  if(results < 1){ let minutes = ((duration._data.minutes)) + " m"; return minutes; }
  if(results < 24){ let hours = (Math.trunc(results)) + " h"; return hours; }
  if(results >= 24){ let days = Math.trunc(results / 24) + " d"; return days; }
  
  //-- If for some reason gets to this point, return nothing. ( shouldn't happen but being safe )
  return null;
};



//------------------------------------------------------------------------------
//-- FETCHING DATA

//-- Making a URL request and returning results
export const get_URL = async function (url, method, mode) { 
  /* Make a fetch request to a URL, wait for a response, and get results
      Returns 
        results:  Data as JSON
        response: The response from the website
  */
  
  let response =  { "message" : "TBD" }; //-- Container to hold fetch response
  let results =   { "message" : "TBD" }; //-- Container to JSON results from fetch response if success
  let status = false; //-- boolean value used if success or fail
  
  //----------------------------------------------------------------------------
  //-- A. Try to Make Fetch Request
  try{
    //--------------------------------------------------------------------------
    //--------------------------------------------------------------------------
    //-- Part 1 - Make Fetch Request Based on Params
      //-- if defining all three values for standard fetch
      if( url && mode  && method) {
        response = await fetch( 
          url,
          {
            method: method,
            mode  : mode
          }
        );
      }
      //-- if need to request just url and mode but not define method
      else if( url && mode && !method){
        response = await fetch( 
          `${url}`,
          {
            "mode": mode
          }
        );
      }
      //-- if only included URL, try to make the request
      else if( url && !mode && !method){
        response = await fetch( 
          url
        );
      }
      //------------------------------------------------------------------------
      //------------------------------------------------------------------------
      //-- Part 2 Check for Success or Fail Response Message

      //-- GOT SUCCESS RESPONSE
      if (response.ok) { //-- if success to getting this info
        const resultsJSON = await response.json() //-- extract payload and turn into JSON
          .then(data => {  //-- then update results value with results
            results = data;
            status = true;
            return [status, results];
          }) 
          .catch(err => { 
            console.log(`Error: ${err}`) 
            status = false;
            return [status, results];
          }); 
      }

      //-- FOUND WEBSITE, DIDN'T PROVIDE RESPONSE
      else if (!response.ok) { //-- If website did not give a response but was still reachable
        status = true;
        return [status,response];
      }
      //-- otherwise failed 
      else {
        console.log(`helpers.js.get_URL: else:`,response);
        status = false;
        return [status, results];
      }
  }
  //----------------------------------------------------------------------------
  //-- B. Catch Error
  catch(err)
  { //-- Try catching for specific scenarios - some are meant to fail
    
    //-- if Fetch Failed
    if(err.toString().includes("TypeError: Failed to fetch"))
    { //-- if a fetch failure, bad fetch request made so check request variable
      // console.log("TypeError caught")
      results = {
        message : "ERROR: CATCH: TypeError: Failed to fetch. Check internet connection or URL.",
        error   : err
      };
      status = false;
      return [status, results]; //-- online
    }
    else {
      // console.log("//-- helpers.js.getURL - Else catch error. Should never happen..!")
      results = {
        message : "Catch error. Undefined. Check internet connection or URL.",
        error   : err
      };

      status = false;
      return [status, results];
    }
  }
  //----------------------------------------------------------------------------
  //-- END
};
