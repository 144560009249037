import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; //-- Used for click back home button

export default function PageNotFound() {

  //----------------------------------------------------------------------------
  //-- For return button
  const navigate = useNavigate();

  //----------------------------------------------------------------------------
  //-- RETURN FUNCTION
  return (
    <section className='container banner'>
        {/* <!-- 404 error section  --> */}
        <div id="404">
            <div>
                <img alt='404 - Page Not Found' src="https://www.posnation.com/hs-fs/hubfs/images/404_RECEIPT.png?width=400&name=404_RECEIPT.png" />
            </div>
        </div>
        <article>
            <p>Sorry, the page you were looking for at this URL was not found.</p>
            {/* <button type="button"><a href="https://posnation.support">Return Home</a></button> */}
            <button type="button" onClick={() => navigate("/")}>Return Home</button>
            {/* navigate(path); */}
        </article>
    </section>  
  )
};
