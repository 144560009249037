//-- IMPORTS
import React, { useState, useEffect } from 'react';

//-- HELPERS

import {
    get_URL //-- takes `url`, `method`, and `mode` as ARGs, returns  results(json) + response(fetch response)
} from '../../utils/helpers';

export default function Statusbar() {
    //TODO:: 05/03/22 #EP || Review and determine how src/js/status-logic.js can be moved into here.
    
    //-- ONLINE STATUS 
    const [updated, setUpdated] = useState(false); //-- Use Effect Updates this. If status values can be updated ( if offline )
    const [updating, setUpdating] = useState(false); //-- Is it currently updating

    //-- SOURCES APPEARING IN STATUSBAR
    const [ sources, ] = useState({ //-- URLs to check for status bar with fetch parameters to be used by helper get_URL()
        "posnation.com" : {
            name    : "posnation.com",
            url     : "https://www.posnation.com",
            method  : "",
            mode    : "no-cors",
            results : {},
            status  : false,
            element : ""
        },
        "bottlepos.com" : {
            name    : "bottlepos.com",
            url     : "https://www.bottlepos.com",
            method  : "",
            mode    : "no-cors",
            results : {},
            status  : false,
            element : ""
        },
        "help.posnation.com" : {
            name    : "help.posnation.com",
            url     : "https://help.posnation.com/",
            method  : "",
            mode    : "no-cors",
            results : {},
            status  : false,
            element : ""
        }
        //},
        // "ip" : {
        //     url     : "https://ipinfo.io/json?token=5ab2f0f1e50427",
        //     method  : "",
        //     mode    : "",
        //     results  : {},
        //     status  : false
        // }
    });


    //--------------------------------------------------------------------------
    //-- BUILD ELEMENTS
    const buildStatusElement = (source) => { //-- Build each statusbar source element dynamically based on results
        // console.log("Statusbar.buildStatusElement(source)... ",source)
        
        if(source){ //-- if provided content to update
            // console.log("buildStatusElement(source):source.status:",source.status)
            return(
                <span key={`status-${source.name}`} className='status'>
                    <span>
                        {/* If Statusbar is still being loaded, state is false so shows spinner */}
                        { updated === false
                            ?   <i className="statusIcon fa fa-spinner fa-pulse fa-fw"></i>
                            //-- If offline or online depending on status as true or false
                            :   <i 
                                    className={`statusIcon fa fa-circle ${source.status === false ? "offline" : "online"}`}
                                    id={`status-${source.name}`}
                                >
                                </i>
                        } {source.name}
                        
                    </span>
                </span>
            )
        }
        // else {
        //     console.log("Statusbar.buildStatusElement(source):source:", source)
        // }
    }

    //--------------------------------------------------------------------------
    //-- LOGIC RUNNING STATUSBAR
    const handleUpdateStatus = async (event) => {

        
        if(!updating){ //-- If already updated ( not spamming click and not currently looking )
            setUpdating(false); //-- set to prevent multiple attempts
            setUpdated(false); //-- set state to false because refreshing so no longer true
            
            const waitToUpdate = setTimeout(() => { //-- Wait 1 second then update            
                return getUpdatedStatus()
            },2000);

            return waitToUpdate;
        }
    }


    const getUpdatedStatus = async () => { //-- Check Status of sources and update StatusBar elements accordingly
        
        if (navigator.onLine){  //-- Update statusbar if online, otherwise do not try
            Object.keys(sources).map( (source, index) => ( //-- Check status of each source one-by-one and update element value accordingly
            
                get_URL( sources[source].url, sources[source].method, sources[source].mode ) //-- send in URL, fetch method and mode, get booling and fetch promise response
                    //-- Update state values of each URL
                    .then( results => {
                        // console.log(`Updating ${JSON.stringify(sources[source])} status from ${sources[source].status} to ${results[0]}`)
                        sources[source].status = results[0];
                    })
            ));

            setUpdated(true); //-- update happened
            setUpdating(false); //-- finished running update event
            return true; 
        }
        
        else {
            // console.log('offline');
            document.getElementById("online-status").innerText = " Network Offline"
            setUpdated(false);
            setUpdating(false);
            //-- wait 5 seconds and try again
            const waitToUpdate = setTimeout(() => { //-- Wait 1 second then update            
                getUpdatedStatus()
            },5000);
            return false;
        }
    };





    //-- EFFECT
    useEffect( () => {
        if(updating === false){
            // console.log("//-- Statusbar.useEffect()...")
            // setUpdating(true)
            setUpdating( true );
            handleUpdateStatus();
        }

        const interval = setInterval(() => {
            if(updating === false){
                setUpdating(true);
                handleUpdateStatus();
            }
        },30000);
        return () => clearInterval(interval);

      },[]);
    

    //--------------------------------------------------------------------------
    //-- RETURN FUNCTION
    return (
    
    <div className='statusbar'>        
        {/* Container holding Server Status Refreshing Text and Icon */}
        <span   className='tooltip'
                id='statusbar-refresh'
                key='statusbar-refresh'
                onClick={handleUpdateStatus}
        >
            <label className='tooltiptext'>Click to Refresh manually.</label>
            <span>
                {(() => {
                    switch(updated) {
                        case false: return (
                            <h4>
                                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                <span id='online-status'> Checking</span>
                            </h4>
                        );
                        case true:  return (
                            <h4>
                                <i className='fa fa-refresh'></i>
                                <span id='online-status'> Online</span>
                            </h4>
                        );
                        default:    return("loading");
                    }
                })()}
                {/* Check to see if done fetching statusbar requests */}
                {/* {updated === false
                    //-- If updated, don't show refreshing icon
                    ?   <>
                            <h4>
                                <i className="fa fa-spinner fa-pulse fa-fw"></i> Online Status
                            </h4>
                        </>
                    //-- Otherwise false and is still fetching/refreshing
                    :   <>
                            <h4>
                                <span className='fa fa-refresh'></span> Online Status
                            </h4>
                        </>
                }  */}
            </span>
        </span>
        
        {/* Build Statusbar Results when based on state and when state changes */}
        {/* {updated === false
            ?   <>
                    {Object.keys(sources).map( (source, index) => (
                        // console.log("//-- Statusbar.return()...source,updated: ",source,updated),
                        // handleUpdateStatus()
                        // getUpdatedStatus()
                        // buildStatusElement(sources[source])
                        "Loading..."
                    ))}
                </>
            :   <>
                    {Object.keys(sources).map( (source, index) => (
                        buildStatusElement(sources[source])
                    ))}
                </>
        } */}

        {(() => {
            switch(updated) {
                case false: return (
                    <>
                        {Object.keys(sources).map( (source, index) => (
                            buildStatusElement(sources[source])
                        ))
                        }
                        
                    </>
                );
                case true:  return (
                    Object.keys(sources).map( (source, index) => (
                        buildStatusElement(sources[source])
                    ))
                );
                default:    return("loading");
            }
        })()}


        </div> 

  )
};
