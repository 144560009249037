import React from 'react';
import { Link } from 'react-router-dom';

import Statusbar from '../Statusbar';

//-- assets
import posnLogo from '../../assets/images/POSN-logo-white.png'

export default function Navigation() {
  return (
  <header>
    {/* Navigational Container */}
    <nav className="nav css-transitions-only-after-page-load disable-select">
      {/* <a className="logo" href="./index.html"> */}
          
        {/* <!-- <img 
            src="https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=175&amp;height=39&amp;name=POSN-logo-white.png"
            alt="POS Nation Logo"
            loading="lazy"
            srcSet="https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=88&amp;height=20&amp;name=POSN-logo-white.png 88w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=175&amp;height=39&amp;name=POSN-logo-white.png 175w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=263&amp;height=59&amp;name=POSN-logo-white.png 263w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=350&amp;height=78&amp;name=POSN-logo-white.png 350w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=438&amp;height=98&amp;name=POSN-logo-white.png 438w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=525&amp;height=117&amp;name=POSN-logo-white.png 525w"
        > --> */}
        <Link className='logo-link' to='/'>
            <span className="logo">
                {/* <img src="https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=175&amp;height=39&amp;name=POSN-logo-white.png" alt="POS Nation Logo" loading="lazy" width="175" height="39" className="navbar-brand-light" srcSet="https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=88&amp;height=20&amp;name=POSN-logo-white.png 88w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=175&amp;height=39&amp;name=POSN-logo-white.png 175w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=263&amp;height=59&amp;name=POSN-logo-white.png 263w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=350&amp;height=78&amp;name=POSN-logo-white.png 350w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=438&amp;height=98&amp;name=POSN-logo-white.png 438w, https://www.posnation.com/hs-fs/hubfs/Logos/POSN-logo-white.png?width=525&amp;height=117&amp;name=POSN-logo-white.png 525w" sizes="(max-width: 175px) 100vw, 175px" /> */}
                <img src={posnLogo} alt='posnation.com logo' />
                {/* POSN-logo-white.webp */}
                <span className='landing-name'>
                    <h1>- Technical Support</h1>
                </span>
            </span>
        </Link>
        {/* PAX Lookup */}
        <span className="nav-item">
            <Link className="nav-ele" id="" to='/pax'>PAX Lookup</Link>
        </span>

        {/* Nav Elements */}
        <span className="nav-item">
        <Link className="nav-ele" id="" to='/ip'>Public IP Lookup</Link>
        </span>

        {/* AWS RESOURCES */}
        {/* <span className="nav-item">
            <Link className="nav-ele" id="" to='/downloads'>Downloads</Link>
        </span> */}
        
      {/* <!-- RESOURCES --> */}
      <span className="nav-item">
        <Link 
            className="nav-ele"
            id=""
            to='/downloads'
        >
            Downloads
        </Link>
          
          <div className="dropdown-menu">
              <section>
                  <span>
                      <h3>
                          Image Manager
                      </h3>
                      {/* <span>
                          <a href='./cap_current.html'>POS Nation's Image Manager About</a>
                      </span> */}
                      <span>
                          <a href='https://posnation.s3.amazonaws.com/Tools%20%28POS%20Nation%29/Image%20Manager/ImageManager.exe'>Download Image Manager</a>
                      </span>
                  </span>
              </section>

              <section>
                  <span>
                      <h3>
                          Tech Tools
                      </h3>
                      <span>
                          <a href='https://posnation.s3.amazonaws.com/Tools%20%28POS%20Nation%29/TechTools/TechTools.exe'>Download Tech Tools</a>
                      </span>
                  </span>
              </section>
          </div>
      </span>
      
      {/* <!-- ABOUT --> */}
      {/* <span className="nav-item">
          <a className="nav-ele" href='/'>
              About  */}
              {/* <span className="nav-link-arrow fa fa-angle-down"></span> */}
          {/* </a>
      </span> */}

      {/* <!-- SETTINGS --> */}
      {/* <span className="nav-item" id="nav-settings">
          <a className="nav-ele" id="nav-settings-a">
              Settings
              <span className="nav-link-other fa fa-gear"></span>
          </a>
      </span> */}
      <Statusbar />
    </nav>
  </header>    
)}
