import React from 'react';
import Chat from '../../components/SalesforceAgent';

export default function Sandbox() {

  const liveAgent = () => { 
    var initESW = function(gslbBaseURL) {
      this.settings.displayHelpButton = true; //Or false
      this.settings.language = ''; //For example, enter 'en' or 'en-US'
  
      //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
      //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
  
      //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
      //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
  
      // Settings for Chat
      //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
        // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
        // Returns a valid button ID.
      //};
      //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
      //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
      //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
  
      this.settings.enabledFeatures = ['LiveAgent'];
      this.settings.entryFeature = 'LiveAgent';
  
      this.init(
        'https://wise-narwhal-5iqd0d-dev-ed.my.salesforce.com',
        'https://posnation-wn-developer-edition.na150.force.com/liveAgentSetupFlow',
        gslbBaseURL,
        '00D4x000006tgxj',
        'Chat_Agents',
        {
          baseLiveAgentContentURL: 'https://c.la3-c1-ia4.salesforceliveagent.com/content',
          deploymentId: '5724x000000DL8e',
          buttonId: '5734x000000DLTb',
          baseLiveAgentURL: 'https://d.la3-c1-ia4.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Chat_Agents',
          isOfflineSupportEnabled: false
        }
      );
    };
  
    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', 'https://wise-narwhal-5iqd0d-dev-ed.my.salesforce.com/embeddedservice/5.0/esw.min.js');
      s.onload = function() {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
  }

  return (
    <div className='page content'>
        {/* <section className='container resources' id='sandbox'>
            <article>  
                <h2>Sandbox</h2>
                <div>
                    <p>
                      Used for testing features and functionality.
                    </p>
                    05/31/2022 #EP | Using for Salesforce Chat Bot Testing with Kyle's development account.
                    
                </div>
            </article>
        </section> */}


        {/* My Custom Chat */}
        <Chat />
        {liveAgent()}
    </div>
  )
}
