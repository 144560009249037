import React from 'react'

export default function resources() {
  return (
    <div className='content'>
        <section className='container resources' id='downloads'>
            
            <h2>Downloads</h2>
            <section className='downloads'>  
                <section className='resource'>    
                    <a 
                        href='https://posnation.s3.amazonaws.com/Tools%20%28POS%20Nation%29/Image%20Manager/ImageManager.exe'
                    >
                        <h3>
                            Image Manager
                        </h3>
                    
                        Download Now
                    </a>
                </section>

                <section className='resource'>
                    <a 
                        href='https://posnation.s3.amazonaws.com/Tools%20%28POS%20Nation%29/TechTools/TechTools.exe'
                    >
                        <h3>
                            Tech Tools
                        </h3>
                    
                        Download Now
                    </a>
                </section>
            </section>
        </section>
    </div>
  )
}


        
