import React from 'react'

export default function Modals() {
  return (
    <div>
        
        {/* <!-- The Modal middle of screen -unused at this time --> */}
        <section>
            <div id="myModal1" className="modal1">
                
                {/* <!-- Modal content --> */}
                <div className="modal-content1">
                    <span className="close1">&times;</span>
                    <p>Some text in the Modal..</p>
                </div>
                
            </div>
        </section>

        {/* <!-- RESOURCES MODAL --> */}
        <section>
            {/* <!-- The Modal --> */}
            <div id="modal-Resources" className="modal">
                {/* <!-- All Content within Modal --> */}
                <div className="modal-content">
                    {/* <!-- title banner with X to close --> */}
                    <div className="modal-header">
                        <h2>
                            Resources 
                            <span className="nav-link-arrow fa fa-book"></span>
                        </h2>
                        <span className="close-resources">&times;</span>
                    </div>
                    {/* <!-- Content within Modal --> */}
                    <div className="modal-body">
                        <section className='nav container resources'>
                            <h2 className="modal-body-section-header">CAP Software</h2>
                            <article>
                                {/* <!-- <form method="get" action="./cap_current.html">
                                    <h4>CAP Current Patch Bucket</h4>
                                    <p className='note'>A link to the current Module Patches for the CAP full installer version 09.5.015.</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        CAP 09.5.015
                                    </button>
                                </form> --> */}
                            
                                <form method="get" action="./cap_all.html">
                                    <h4>All CAP Module Downloads</h4>
                                    <p className='note'>A link to ALL cap module downloads and related resources.</p>
                                    <button className="btn_Resources" type="submit">
                                        All CAP Versions
                                    </button>
                                </form>
                            </article>    
                        </section>

                        {/* <!-- DRIVERS -->
                        <!-- <section className='nav container resources'>
                            <h2 className="modal-body-section-header">Drivers</h2>
                            <article>
                                <form method="get" action="#">
                                    <h4>Category 1</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>Category 2</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>
                            </article>
                        </section> --> */}
                        {/* <!-- <section className='nav container resources'>
                            <h2 className="modal-body-section-header">Drivers</h2>
                            <article>
                                <form method="get" action="#">
                                    <h4>Printer</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>PC</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>Scanner</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>
                            </article>
                            
                        </section> --> */}


                    </div>

                    <section className="modal-footer"></section>
                </div>
            </div>
        </section>


        {/* <!-- SETTINGS MODAL --> */}
        <section>
            {/* <!-- The Modal --> */}
            <div id="modal-Settings" className="modal">
                {/* <!-- All Content within Modal --> */}
                <div className="modal-content">
                    
                    {/* <!-- title banner with X to close --> */}
                    <div className="modal-header">
                        <h2>
                            Settings 
                            <span className="nav-link-arrow fa fa-gear"></span>
                        </h2>
                        <span className="close-settings">&times;</span>
                    </div>
                    
                    {/* <!-- Content within Modal --> */}
                    <div className="modal-body">
                        
                        {/* <!-- Category 1 --> */}
                        <section className='nav container resources'>
                            <h2 className="modal-body-section-header">Coming Soon</h2>
                            <article>
                                <form method="get" action="#">
                                    <h4>Category 1</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Settings" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>Category 2</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Settings" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>
                            </article>
                        </section>
                        {/* <!-- <section className='nav container resources'>
                            <h2 className="modal-body-section-header">Drivers</h2>
                            <article>
                                <form method="get" action="#">
                                    <h4>Printer</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>PC</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>

                                <form method="get" action="#">
                                    <h4>Scanner</h4>
                                    <p className='note'>Drivers coming soon!</p>
                                    <button className="btn_Resources" style="margin:5px;" type="submit">
                                        Coming Soon!
                                    </button>
                                </form>
                            </article>
                            
                        </section> --> */}


                    </div>

                    <section className="modal-footer"></section>
                </div>

            </div>
        </section>
    </div>
  )
}
