//------------------------------------------------------------------------------
//-- Imports
import React, {useState} from 'react';

//------------------------------------------------------------------------------
//-- PaxLookup Function
export default function PaxLookup() {

  //----------------------------------------------------------------------------
  //-- States
  const [paxSerial, setPaxSerial] = useState();

  //----------------------------------------------------------------------------
  //-- Functionality

  //-- When PAX Serial Number is being typed
  function onKeyUp(event){

    
    if(event.key === "Backspace" || event.key === 'Delete')
    { //-- if trying to erase data, don't try to format, EXIT
      return null;
    } 
    
    const paxSerialInput = event.target.value;   //-- extract value from input
    
    //-- Check if input is empty -----------------------------------------------
    if(!paxSerialInput) return; //-- if nothing typed yet EXIT

    //-- IF input does exist ---------------------------------------------------
    //-- prepare var
    const digits = paxSerialInput.replace(/\D/g, ''); //-- Remove everything except for digits
    let formattedPaxSerial = ''; //-- Create variable to hold the SN that's updating state, page, and API request.
    
    //-- Check Length and format accordingly -----------------------------------
    if(digits.length < 9 )
    {   //-- If less than 9, just add single hyphen
      formattedPaxSerial = ( digits.substring(0,4) + '-' + digits.substring(4,8)); //-- format with hyphens
    }
    if(digits.length >= 9 )
    { //-- if more than 9 characters, add another hyphen
      formattedPaxSerial = ( digits.substring(0,4) + '-' + digits.substring(4,8) + '-' + digits.substring(8,12) ); //-- format with hyphens
    }
    
    //-- inline styling so red border until good.
    var input = event.target;
    var isError = ( (formattedPaxSerial.length) < 13 );
    var color =  (isError) ? "red" : "grey";
    var borderWidth =  (isError)? "3px" : "1px"; 
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
     
    //-- update ui input
    event.target.value = formattedPaxSerial;
    //-- update data to send
    setPaxSerial(formattedPaxSerial);
    // console.log(paxSerial)
    return null;
  }


  return (
    <div className='content'>
      <section className='container resources' id='pax-section'>
        {/* <!-- PAX Submission form results --> */}
        {/* <section> */}
          <h2>PAX Pin-Pad Lookup</h2>
          <article className='' id="pax-request-results">  
            
            {/* the button form */}
            
            {/* //onSubmit= This is controlled in assets/js/pax.js */}
            <form
              className="pax-form"
              
              // onSubmit
            >
              <label htmlFor='pax-sn'><h4>Enter a PAX Serial Number</h4></label>
              <p className='note'>Enter PAX SN to retrieve online state.</p>
              <input
                type="text"
                id="pax-sn"
                minLength="9"   //-- single hyphen value
                maxLength="14"  //--  multi hyphen value
                required
                onKeyUp={onKeyUp}
              />
              <span className='tooltip'>
                <label className='tooltiptext'>Enter an 8 - 12 digit Serial Number, and press Lookup to verify PAX details.</label>
                <button className="folder_BTN" id="pax-lookup-submit" type="button"> 
                  Lookup
                </button>
              </span>
            </form>
            
            {/* the results container */}
            <div id="pax-lookup-results">
              {/* <!-- TODO:: 03/22/2022 #EP || Add Copy Button --> */}
              {/* <span>
                <h4 id="pax-h4">PAX Lookup Results</h4>
              </span> */}
              <span className='pax-lookup-result'>
                <code className='label'>SerialNo</code>
                <code className='results' id="pax-SerialNo">-</code>
              </span>
              <span className='pax-lookup-result'>
                <code className='label'>ResultMsg</code>
                <code className='results' id="pax-ResultMsg">-</code>
              </span>
              <span className='pax-lookup-result'>
                <code className='label'>ResultCode</code>
                <code className='results' id="pax-ResultCode">-</code>
              </span>
              <span className='pax-lookup-result'>
                <code className='label'>IPaddress</code>
                <code className='results' id="pax-IPaddress">-</code>
              </span>
              <span className='pax-lookup-result'>
                <code className='label'>MacAddress</code>
                <code className='results' id="pax-MacAddress">-</code>
              </span>
              <span className='pax-lookup-result'>
                <code className='label'>Port</code>
                <code className='results' id="pax-Port">-</code>
              </span>
            </div>
          
          </article>            
        </section>
      {/* </section> */}
    </div>
  )
}
